@font-face {
	font-family: 'Nyala W01 Regular';
	src: local('../fonts/geez/nyala.woff2') format('woff2'), local('../fonts/geez/nyala.woff') format('woff');
}

@font-face {
	font-family: 'Titillium Web';
	src: local('../fonts/titillium_web/TitilliumWeb-Regular.woff2') format('woff2'),
		local('../fonts/titillium_web/TitilliumWeb-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: local('../fonts/titillium_web/TitilliumWeb-Bold.woff2') format('woff2'),
		local('../fonts/titillium_web/TitilliumWeb-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	src: local('../fonts/titillium_web/TitilliumWeb-SemiBold.woff2') format('woff2'),
		local('../fonts/titillium_web/TitilliumWeb-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: 'Titillium Web', 'Nyala W01 Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* LOADER */
#loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #059348; /* green */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* <script
										type="text/javascript"
										src="https://assets.calendly.com/assets/external/widget.js"
									></script> */
